<template>
    <div>
        <!--Begin::Add Offer-->
        <KTPortlet>
            <template v-slot:toolbar></template>
            <template v-slot:body>
                <tagProcessor></tagProcessor>
            </template>
        </KTPortlet>
        <!--End::Add Offer-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import tagProcessor from "@/views/tagProcessor/TagProcessor.vue";

export default {
    name: "offer",
    components: {
        KTPortlet,
        tagProcessor
    },
    data() {
        return {};
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.$t("OFFER.TITLE") }
        ]);
    },
    methods: {}
};
</script>
